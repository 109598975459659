<template>
  <div class="page">
    <div class="top">
      <h3>数字化管理平台架构</h3>
      <h1>流动人口和出租房屋管理平台</h1>
      <h4>科学高效的数字化管理平台架构，形成出租房屋流动人口的管控，降低基层治理<br>难度，做到政府导治、居民自治、平台数治</h4>
      <div class="btn_wrap">
        <a-space size="large">
          <a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
            <div class="button btn-size">预约演示</div>
          </a>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
            <div class="button plain btn-size">免费试用</div>
          </a>
        </a-space>
      </div>
    </div>
    <div class="part">
      <h2>流动人口监管难点分析</h2>
      <h4>深度剖析流动人口监管的难点与对策</h4>
      <a-row :gutter="[24, { xs: 12, sm: 12, md: 24 }]">
        <a-col :span="8" :md="8" :sm="24" :xs="24">
          <div class="product_item">
            <img src="@/assets/image/ProductRent/icon1.png" alt="" />
            <div class="item_left">
              <h4>政府</h4>
              <p>数据来源分散，人员排查和房东填报信息准确度不高，纠纷问题不知道找谁，只会打12345。</p>
            </div>
          </div>
        </a-col>
        <a-col :span="8" :md="8" :sm="24" :xs="24">
          <div class="product_item">
            <img src="@/assets/image/ProductRent/icon2.png" alt="" />
            <div class="item_left">
              <h4>房东</h4>
              <p>缺乏规范管理意识和管理工具，房东年龄较大或已移居外地动态管理不到位。</p>
            </div>
          </div>
        </a-col>
        <a-col :span="8" :md="8" :sm="24" :xs="24">
          <div class="product_item">
            <img src="@/assets/image/ProductRent/icon3.png" alt="" />
            <div class="item_left">
              <h4>租客</h4>
              <p>租房贵、房源价格不透明，遇到换房等问题沟通难，管理服务跟不上。</p>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="part">
      <h2>构建数字化管理平台架构</h2>
      <h4>构建全方位、一体化、高性能的数字化管理平台架构</h4>
      <div class="digital_wrap">
        <img src="@/assets/image/ProductRent/digital.png" alt="" v-if="screenWidth > 768" />
        <img src="@/assets/image/ProductRent/digital-m.png" alt="" v-else />
      </div>
    </div>
    <div class="tab">
      <div class="part">
        <h2>平台展示</h2>
        <h4>搭建出租屋管理数据底座</h4>
        <div class="tab_wrap">
          <div
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ tab_item: true, check: tabActive == index }"
            @click="tabActive = index"
          >
            {{ item }}
          </div>
        </div>
        <div class="tab_content" v-show="tabActive == 0">
          <div class="cockpit_text right">
            <h3>房东房源信息管理</h3>
            <p>房东在线上登记个人信息和房源信息<br>工作人员在线审核后即可完成房屋的备案和更新</p>
            <h3>出租房安全管理</h3>
            <p>提供线上出租房消防安全检查表<br>要求房东及承租人定期检查房屋安全隐患<br>在线反馈检查结果</p>
          </div>
          <div class="img-wrap">
            <img src="@/assets/image/ProductRent/tab1.png" alt="" />
          </div>
          <div class="cockpit_text">
            <h3>招租信息在线发布</h3>
            <p>房东在线发布招租信息，承租人在线获取真实房源信息<br>减少租房相关的隐患，房东、承租人在线上完成资询沟通</p>
            <h3>承租人信息管理</h3>
            <p>承租人在线上提交个人信息登记<br>工作人员在线审核后即可完成租房流程<br>系统自动更新房屋状态</p>
          </div>
        </div>
        <div class="tab_content" v-show="tabActive == 1">
          <div class="cockpit_text right">
            <h3>增收</h3>
            <p>平台出租（流量）、政策补贴<br>改造提升、增值服务（保洁生活服务）</p>
            <h3>服务好</h3>
            <p>房东服务、第三方服务<br>社区服务在线、出现问题快速对接负责人</p>
          </div>
          <div class="img-wrap">
            <img src="@/assets/image/ProductRent/tab2.png" alt="" />
          </div>
          <div class="cockpit_text">
            <h3>便捷</h3>
            <p>电子钥匙、合同在线<br>缴费在线、服务在线</p>
            <h3>合规</h3>
            <p>合同标准、租客和房东谈价<br>税务合规、保险</p>
          </div>
        </div>
        <div class="tab_content" v-show="tabActive == 2">
          <div class="img-wrap tab3">
            <img src="@/assets/image/ProductRent/tab3.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="part">
      <h2>成功案例</h2>
      <h4>北臧村镇流动人口-出租房管理数字平台解决方案</h4>
      <div class="case_wrap">
        <div>
          <img src="@/assets/image/ProductRent/case.png" alt="" />
        </div>
        <div>
          <h1>寓信科技携手大兴区北臧村镇人民政府搭建流动人口和出租房屋管理系统</h1>
          <p>在保障重点项目落地方面，北臧村镇人民政府超前布局、提前落子，积极推进村庄公寓项目建设，协助生物医药基地解决职住平衡问题，因此也需要一套智慧系统来辅助搭建流动人口监管平台和出租房屋管理系统。</p>
          <span>2024-06-19 17:50:00</span>
        </div>
      </div>
    </div>
    <div class="part bottom_wrap">
      <h2>更懂企业的管理痛点，陪伴客户走向成功</h2>
      <h4>专家团队实践经验丰富，提供一站式咨询服务</h4>
    </div>
    <div class="bottom_bg">
      <div class="exhibition_wrap">
        <div class="exhibition_item">
          <img src="@/assets/image/ProductApartment/bottom1.png" alt="" />
          <div>
            <h4>售前咨询</h4>
            <p>
              资深的行业方案专家
              <br />
              根据您的诉求设计解决方案
            </p>
          </div>
        </div>
        <div class="exhibition_item">
          <img src="@/assets/image/ProductApartment/bottom2.png" alt="" />
          <div>
            <h4>实施上线</h4>
            <p>
              专业的实施团队，根据企业的业务场景
              <br />
              进行安装部署与系统配置
            </p>
          </div>
        </div>
        <div class="exhibition_item">
          <img src="@/assets/image/ProductApartment/bottom3.png" alt="" />
          <div>
            <h4>售后及运营</h4>
            <p>
              可靠的售后及运营团队，提供使用培训与
              <br />
              案例分享，持续为企业迭代产品和服务
            </p>
          </div>
        </div>
      </div>
      <a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
        <div class="button btn-size exper">立即咨询</div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductRent",
  data() {
    return {
      screenWidth: document.body.clientWidth,
      tabList: [
        "居民信息管理",
        "房东端租房管理",
        "租客服务平台"
      ],
      tabActive: 0
    }
  },
  methods: {},
  created() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  }
}
</script>

<style scoped lang="less">
h1, h2, h3, h4, p {
  margin-bottom: 0;
}
.button {
  background: #2267f6;
  border-radius: 2px;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.plain {
  border: 2px solid #0177ff;
  background-color: transparent;
  color: #0177ff;
  border-radius: 2px;
}
.btn-size {
  height: 50px;
  width: 160px;
  font-size: 18px;
}
.top {
  height: 550px;
  padding-top: 70px;
  background: url("../assets/image/ProductRent/bg.png") no-repeat center;
  background-size: cover;
  background-position: 100% 100%;
  h3 {
    width: 1200px;
    margin: 0 auto;
    padding-top: 100px;
    font-size: 26px;
    color: #ffffff;
  }
  h1 {
    color: #fff;
    width: 1200px;
    margin: 0 auto;
    font-size: 44px;
    font-weight: bold;
  }
  h4 {
    color: #fff;
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    padding-top: 20px;
  }
  .btn_wrap {
    width: 1200px;
    margin: 31px auto;
  }
}
.part {
  width: 1200px;
  margin: 0 auto;
  padding: 54px 0;
  text-align: center;
  .type-list {
    padding: 0 50px;
  }
  h2 {
    font-weight: 700;
    font-size: 36px;
    color: #030d1e;
  }
  h4 {
    margin-bottom: 40px;
    font-size: 20px;
    color: #545558;
    font-weight: 400;
  }
  .bottom_wrap {
    padding-bottom: 35px;
  }
  .product_item {
    display: flex;
    padding: 42px 32px;
    border: 1px solid #ffffff;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
    img {
      height: 56px;
      margin-right: 29px;
    }
    h4 {
      font-size: 24px;
      margin-bottom: 7px;
      font-weight: bold;
      text-align: left;
    }
    p {
      font-size: 16px;
      color: #808082;
      text-align: left;
    }
  }
}
.digital_wrap {
  margin: 10px;
  img {
    width: 100%;
  }
}
.tab {
  background-color: #f5f6fa;
}
.tab_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4edfc;
  .tab_item {
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 20px;
    cursor: pointer;
    width: 200px;
  }
  .check {
    position: relative;
  }
  .check::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 4px;
    background: #0060ff;
  }
}
.tab_content {
  margin-top: 54px;
  display: flex;
  align-items: center;
  & > div {
    flex: 1;
  }
  .cockpit_text {
    text-align: left;
    &.right {
      text-align: right;
    }
    h3 {
      color: #030d1e;
      font-size: 20px;
      font-weight: 700;
      margin: 20px 0 0;
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      color: #607499;
      margin: 10px 0 20px;
    }
  }
  .img-wrap {
    margin: 0 60px;
    &.tab3 {
      margin-top: 14px;
      img {
        width: 60%;
      }
    }
    img {
      width: 100%;
    }
  }
}
.case_wrap {
  display: flex;
  align-items: center;
  text-align: left;
  width: 1000px;
  margin: 0 auto;
  & > div {
    flex: 1;
    padding: 0 20px;
  }
  img {
    width: 100%;
  }
  h1 {
    font-size: 20px;
  }
  p {
    margin: 20px 0 30px;
    font-size: 14px;
    color: #607499;
  }
  span {
    font-size: 14px;
    color: rgba(3, 13, 30, 0.26);
  }
}
.exper {
  margin: 0 auto;
  margin-top: 55px;
}
.bottom_bg {
  height: 554px;
  width: 100%;
  background: url("../assets/image/ProductApartment/bottom_bg.png") no-repeat
    center;
  background-size: cover;
  padding-top: 64px;
  text-align: center;
  .exhibition_wrap {
    padding: 0 20px 40px 90px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .exhibition_item {
      text-align: center;
      h4 {
        color: #3d3d3d;
        font-size: 20px;
        margin: 10px 0;
      }
      img {
        height: 100px;
        width: 100px;
      }
      p {
        font-size: 16px;
        color: #545558;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .page {
    width: 100vw;
    overflow: hidden;
  }
  .btn-size {
    height: 29px;
    width: 72px;
    font-size: 12px;
    font-weight: 700;
  }
  .top {
    height: 200px;
    background: url("../assets/image/ProductRent/bg-m.png") no-repeat center;
    background-size: cover;
    background-position: 100% 100%;
    margin-top: 50px;
    padding: 0 12px;
    h3 {
      padding: 60px 0 4px;
      font-size: 16px;
    }
    h1 {
      font-size: 20px;
    }
    h4, .btn_wrap {
      display: none;
    }
  }
  .part {
    width: 100%;
    padding: 14px 0;
    h2 {
      font-size: 16px;
    }
    h4 {
      font-size: 12px;
      margin-bottom: 10px;
    }
    .bottom_wrap {
      padding-bottom: 24px;
    }
    .product_item {
      margin: 0 12px;
      padding: 20px 24px;
      img {
        height: 48px;
        margin-right: 24px;
      }
      .item_left {
        h4 {
          font-size: 14px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
  .tab_wrap {
    justify-content: space-evenly;
    flex-wrap: wrap;
    .tab_item {
      width: 128px;
      font-size: 12px;
      margin-top: 12px;
    }
    .check::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: #0060ff;
    }
  }
  .tab_content {
    margin: 0 12px;
    display: block;
    .cockpit_text {
      margin: 0 auto;
      h3 {
        font-size: 14px;
        text-align: center;
        margin-top: 20px;
      }
      p {
        font-size: 12px;
        text-align: center;
        line-height: 1.5;
      }
    }
    .img-wrap {
      width: 50%;
      margin: 0 auto;
      &.tab3 {
        width: 100%;
        img {
          width: 80%;
        }
      }
    }
  }
  .case_wrap {
    display: initial;
    margin: 0 12px;
    img {
      margin-bottom: 20px;
    }
    p {
      margin: 10px 0 20px;
    }
  }
  .exper {
    margin-top: 24px;
  }
  .bottom_bg {
    height: 500px;
    width: 100%;
    background: url("../assets/image/ProductApartment/bottom_bg_mobile.png")
      no-repeat center;
    background-size: cover;
    padding-top: 0;
    text-align: center;
    .exhibition_wrap {
      width: 100%;
      padding: 0 40px;
      flex-wrap: wrap;
      .exhibition_item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 52px;
        img {
          height: 47px;
          width: 47px;
          margin-right: 16px;
        }
        h4 {
          font-size: 14px;
          text-align: left;
          font-weight: 600;
          margin: 0;
        }
        p {
          font-size: 12px;
          text-align: left;
        }
      }
    }
  }
}
</style>